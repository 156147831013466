export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path == '/cosmetica.html')
    return navigateTo('/es/cosmetica', { redirectCode: 301 })

  if (to.path == '/maquillaje.html')
    return navigateTo('/es/maquillaje', { redirectCode: 301 })

  if (to.path == '/perfume.html')
    return navigateTo('/es/perfumeria', { redirectCode: 301 })
})
