export default defineNuxtRouteMiddleware((to, from) => {
  if (!import.meta.server) return

  const allowedParams = ['page'] // Parámetros permitidos
  const originalQuery = { ...to.query }
  const newQuery = {}

  for (const param of allowedParams) {
    if (originalQuery[param] !== undefined) {
      newQuery[param] = originalQuery[param]
    }
  }

  // Reemplazar los parámetros de consulta en 'to' con los parámetros limpios
  to.query = newQuery
})
