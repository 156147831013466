export default defineNuxtPlugin(() => {
  if (process.server) return

  const consent = useState('consent', () => {
    return {
      security: true,
      functionality: false,
      analytics: false,
      marketing: false,
    }
  })

  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  window.gtag = gtag

  function getCookie(name) {
    var cookies = document.cookie.split('; ')
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i]
      var cookieParts = cookie.split('=')
      if (cookieParts[0].trim() === name) {
        return decodeURIComponent(cookieParts.slice(1).join('='))
      }
    }
    return null
  }
  var security = 'granted'
  var analytics = 'denied'
  var functionality = 'denied'
  var marketing = 'denied'
  var consentCookie = getCookie('cc_cookie')
  if (consentCookie) {
    var cc = JSON.parse(consentCookie)
    analytics = cc.categories.includes('analytics') ? 'granted' : 'denied'
    functionality = cc.categories.includes('functionality')
      ? 'granted'
      : 'denied'
    marketing = cc.categories.includes('marketing') ? 'granted' : 'denied'

    consent.value = {
      security: true,
      functionality: functionality == 'granted',
      analytics: analytics == 'granted',
      marketing: marketing == 'granted',
    }
  }

  console.debug('Default consent mode:')
  console.debug('Security consent      :', security)
  console.debug('Analytics consent     :', analytics)
  console.debug('Funcionality consent  :', functionality)
  console.debug('Ads consent consent   :', marketing)

  gtag('consent', 'default', {
    security_storage: security,
    analytics_storage: analytics,
    functionality_storage: functionality,
    personalization_storage: analytics,
    ad_storage: marketing,
    ad_user_data: marketing,
    ad_personalization: marketing,
    wait_for_update: 500,
  })
  gtag('set', 'ads_data_redaction', true)
  gtag('set', 'url_passthrough', true)

  useScript({
    src: 'https://www.googletagmanager.com/gtag/js?id=G-LECT0D3PQJ',
    async: true,
  })

  gtag('js', new Date())
  gtag('config', 'G-LECT0D3PQJ')
})
