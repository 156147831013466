import { default as handlerAkyej7ZYQzMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/__/auth/handler.vue?macro=true";
import { default as _91_46_46_46seotag_93XdD1JnXhEbMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/[...seotag].vue?macro=true";
import { default as access_45data6qAKMakMMiMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/access-data.vue?macro=true";
import { default as edituqlRX4d38FMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/addresses/[id]/edit.vue?macro=true";
import { default as indexAjG1jrPDvKMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/addresses/index.vue?macro=true";
import { default as coupons8Y6zh9DF8lMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/coupons.vue?macro=true";
import { default as indexDJzDIz2TfXMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/creditcards/index.vue?macro=true";
import { default as favouritesLJeTu0tcdoMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/favourites.vue?macro=true";
import { default as indexsq1Zb5hONxMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/index.vue?macro=true";
import { default as loyalty_45pointsGl0CQhuoLCMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/loyalty-points.vue?macro=true";
import { default as notificationsAUBSN80XJpMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/notifications.vue?macro=true";
import { default as index6VjFYc7CNgMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/[id]/index.vue?macro=true";
import { default as indexbQ1CfkSFrXMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/index.vue?macro=true";
import { default as personal_45datasF54mlXrvkMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/personal-data.vue?macro=true";
import { default as preferencesdBL2L22qolMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/preferences.vue?macro=true";
import { default as addpMexXSPAq7Meta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/reviews/add.vue?macro=true";
import { default as stock_45alertsDk7LGZRMk4Meta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/stock-alerts.vue?macro=true";
import { default as newAXx7VoZSG9Meta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/creditcards/new.vue?macro=true";
import { default as braintreeDjazopmK56Meta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/braintree.vue?macro=true";
import { default as klarnaLMBl5AAIXJMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/klarna.vue?macro=true";
import { default as multisafepayMwXSvxQgFHMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/multisafepay.vue?macro=true";
import { default as _91sessionid_93ZUJnYahI94Meta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/paycomet/[result]/[sessionid].vue?macro=true";
import { default as koFG67bv1kJnMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ko.vue?macro=true";
import { default as ok53XCiyItIrMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ok.vue?macro=true";
import { default as challengew4fuKih2rLMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/test/challenge.vue?macro=true";
import { default as _91id_93L11ZjTxWGpMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/product/nutritionfacts/[id].vue?macro=true";
import { default as indexsWBV4KREFgMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/index.vue?macro=true";
import { default as thankubWacDWjIByMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/thanku.vue?macro=true";
import { default as indexABcbKcyYIDMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/conocenos/index.vue?macro=true";
import { default as indexoM8VWNhcFvMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/index.vue?macro=true";
import { default as cookie_45policyTJMFdKYZ2sMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/legal/cookie-policy.vue?macro=true";
import { default as loginXWQ6oqFe7hMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/login.vue?macro=true";
import { default as index1233a978QlMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/marcas/index.vue?macro=true";
import { default as _91result_93VWIwMJ4LVgMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/paycomet/[result].vue?macro=true";
import { default as _91session_93g58Fcee6ItMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/[session].vue?macro=true";
import { default as _91result_93g2sKWjpf4FMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/result/[result].vue?macro=true";
import { default as recoverycw0KRkAMyCMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/recovery.vue?macro=true";
import { default as testSUzxF0D5qhMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/test.vue?macro=true";
import { default as indexaXahWerMDSMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/[id]/index.vue?macro=true";
import { default as indexkEUGxGFhHOMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/index.vue?macro=true";
import { default as _91_46_46_46slug_930mifxpI0vhMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/draft/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93TkxwvOEhdZMeta } from "/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/published/[...slug].vue?macro=true";
export default [
  {
    name: "__-auth-handler",
    path: "/__/auth/handler",
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/__/auth/handler.vue").then(m => m.default || m)
  },
  {
    name: "locale-seotag",
    path: "/:locale()/:seotag(.*)*",
    meta: _91_46_46_46seotag_93XdD1JnXhEbMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/[...seotag].vue").then(m => m.default || m)
  },
  {
    name: "locale-account-access-data",
    path: "/:locale()/account/access-data",
    meta: access_45data6qAKMakMMiMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/access-data.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-addresses-id-edit",
    path: "/:locale()/account/addresses/:id()/edit",
    meta: edituqlRX4d38FMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-addresses",
    path: "/:locale()/account/addresses",
    meta: indexAjG1jrPDvKMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-coupons",
    path: "/:locale()/account/coupons",
    meta: coupons8Y6zh9DF8lMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/coupons.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-creditcards",
    path: "/:locale()/account/creditcards",
    meta: indexDJzDIz2TfXMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/creditcards/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-favourites",
    path: "/:locale()/account/favourites",
    meta: favouritesLJeTu0tcdoMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/favourites.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "/:locale()/account",
    meta: indexsq1Zb5hONxMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-loyalty-points",
    path: "/:locale()/account/loyalty-points",
    meta: loyalty_45pointsGl0CQhuoLCMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/loyalty-points.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-notifications",
    path: "/:locale()/account/notifications",
    meta: notificationsAUBSN80XJpMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-orders-id",
    path: "/:locale()/account/orders/:id()",
    meta: index6VjFYc7CNgMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-orders",
    path: "/:locale()/account/orders",
    meta: indexbQ1CfkSFrXMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-personal-data",
    path: "/:locale()/account/personal-data",
    meta: personal_45datasF54mlXrvkMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/personal-data.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-preferences",
    path: "/:locale()/account/preferences",
    meta: preferencesdBL2L22qolMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-reviews-add",
    path: "/:locale()/account/reviews/add",
    meta: addpMexXSPAq7Meta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/reviews/add.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-stock-alerts",
    path: "/:locale()/account/stock-alerts",
    meta: stock_45alertsDk7LGZRMk4Meta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/stock-alerts.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-creditcards-new",
    path: "/:locale()/app/webview/creditcards/new",
    meta: newAXx7VoZSG9Meta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/creditcards/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-braintree",
    path: "/:locale()/app/webview/payment/gateway/braintree",
    meta: braintreeDjazopmK56Meta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/braintree.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-klarna",
    path: "/:locale()/app/webview/payment/gateway/klarna",
    meta: klarnaLMBl5AAIXJMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/klarna.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-multisafepay",
    path: "/:locale()/app/webview/payment/gateway/multisafepay",
    meta: multisafepayMwXSvxQgFHMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/multisafepay.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-gateway-paycomet-result-sessionid",
    path: "/:locale()/app/webview/payment/gateway/paycomet/:result()/:sessionid()",
    meta: _91sessionid_93ZUJnYahI94Meta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/paycomet/[result]/[sessionid].vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-result-ko",
    path: "/:locale()/app/webview/payment/result/ko",
    meta: koFG67bv1kJnMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ko.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-result-ok",
    path: "/:locale()/app/webview/payment/result/ok",
    meta: ok53XCiyItIrMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ok.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-payment-test-challenge",
    path: "/:locale()/app/webview/payment/test/challenge",
    meta: challengew4fuKih2rLMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/test/challenge.vue").then(m => m.default || m)
  },
  {
    name: "locale-app-webview-product-nutritionfacts-id",
    path: "/:locale()/app/webview/product/nutritionfacts/:id()",
    meta: _91id_93L11ZjTxWGpMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/product/nutritionfacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "locale-checkout-id",
    path: "/:locale()/checkout/:id()",
    meta: indexsWBV4KREFgMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-checkout-id-thanku",
    path: "/:locale()/checkout/:id()/thanku",
    meta: thankubWacDWjIByMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/thanku.vue").then(m => m.default || m)
  },
  {
    name: "locale-conocenos",
    path: "/:locale()/conocenos",
    meta: indexABcbKcyYIDMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/conocenos/index.vue").then(m => m.default || m)
  },
  {
    name: "locale",
    path: "/:locale()",
    meta: indexoM8VWNhcFvMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-legal-cookie-policy",
    path: "/:locale()/legal/cookie-policy",
    meta: cookie_45policyTJMFdKYZ2sMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "locale-login",
    path: "/:locale()/login",
    meta: loginXWQ6oqFe7hMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/login.vue").then(m => m.default || m)
  },
  {
    name: "locale-marcas",
    path: "/:locale()/marcas",
    meta: index1233a978QlMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/marcas/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-public-checkout-payment-gateway-paycomet-result",
    path: "/:locale()/public/checkout/payment/gateway/paycomet/:result()",
    meta: _91result_93VWIwMJ4LVgMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/paycomet/[result].vue").then(m => m.default || m)
  },
  {
    name: "locale-public-checkout-payment-gateway-redsys-session",
    path: "/:locale()/public/checkout/payment/gateway/redsys/:session()",
    meta: _91session_93g58Fcee6ItMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/[session].vue").then(m => m.default || m)
  },
  {
    name: "locale-public-checkout-payment-gateway-redsys-result-result",
    path: "/:locale()/public/checkout/payment/gateway/redsys/result/:result()",
    meta: _91result_93g2sKWjpf4FMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/result/[result].vue").then(m => m.default || m)
  },
  {
    name: "locale-recovery",
    path: "/:locale()/recovery",
    meta: recoverycw0KRkAMyCMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/recovery.vue").then(m => m.default || m)
  },
  {
    name: "locale-test",
    path: "/:locale()/test",
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/test.vue").then(m => m.default || m)
  },
  {
    name: "locale-tiendas-id",
    path: "/:locale()/tiendas/:id()",
    meta: indexaXahWerMDSMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-tiendas",
    path: "/:locale()/tiendas",
    meta: indexkEUGxGFhHOMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/index.vue").then(m => m.default || m)
  },
  {
    name: "storyblok-draft-slug",
    path: "/storyblok/draft/:slug(.*)*",
    meta: _91_46_46_46slug_930mifxpI0vhMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/draft/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "storyblok-published-slug",
    path: "/storyblok/published/:slug(.*)*",
    meta: _91_46_46_46slug_93TkxwvOEhdZMeta || {},
    component: () => import("/Users/nutritienda/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/published/[...slug].vue").then(m => m.default || m)
  }
]